import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoginForm } from "../../redux/actions/authActions";
import { Box,  Dialog, DialogContent, DialogTitle, Tab, Tabs, Typography } from "@mui/material";
import {  EmailOutlined, Lock, PhoneAndroidOutlined, Pin } from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";
import LoginWithPassword from "./LoginWithPassword";
import LoginWithOTP from "./LoginWithOTP";

const LoginModal = () => {
    const showLoginForm = useSelector(state => state.auth.showLoginForm);
    const [selectedLoginMode, setSelectedLoginMode] = useState('otp');
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setShowLoginForm(false));
    }
    const onChangeTab = (e, value) => {
        setSelectedLoginMode(value);
    }

    return (
        <>
            <TabContext value={selectedLoginMode}>
                <Dialog onClose={handleClose} open={showLoginForm} fullWidth maxWidth="xs" PaperProps={{ sx: { width: '100%', m: 0, borderRadius: 5, alignSelf: { xs: 'flex-end', md: 'center' }, minHeight: '470px' } }} disableAutoFocus>
                    <DialogTitle sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontSize: 24 }}>Login</Typography>
                        <Box sx={{ mt: 2, border: 1, borderColor: 'divider', borderRadius: 5, overflow: 'hidden' }}>
                            <Tabs
                                onChange={onChangeTab}
                                value={selectedLoginMode}
                                aria-label="select login type"
                                centered
                                TabIndicatorProps={{ sx: { height: '100%', opacity: 0.15, borderRadius: 5 } }}
                                variant="fullWidth"
                            >
                                <Tab label="OTP" value={"otp"} disableRipple icon={<Pin fontSize="small" />} iconPosition="start" sx={{ minHeight: '48px' }} aria-label="Login using phone numbet and OTP" />
                                <Tab label="Password" value={"password"} disableRipple icon={<Lock fontSize="small" />} iconPosition="start" sx={{ minHeight: '48px' }} aria-label="Login using email and password" />
                            </Tabs>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TabPanel value="password" index={0}>
                            <LoginWithPassword/>
                        </TabPanel>
                        <TabPanel value="otp" index={1}>
                            <LoginWithOTP/>
                        </TabPanel>
                    </DialogContent>
                </Dialog>
            </TabContext>
        </>
    )
}

export default LoginModal;