import { Alert, Box, Button, Card, CardActionArea, CardContent, Divider, Typography } from "@mui/material";
import PrimaryLayout from "../../layout/Layout"
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBookingsByCustomerId, resetBookingList } from "../../redux/actions/apiActions";
import moment from "moment-timezone";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Alarm, BusinessCenter, ConfirmationNumber, CorporateFare } from "@mui/icons-material";
import QRCode from "react-qr-code";
import SkeletonCard from "../../components/Loaders/SkeletonCard";
import { setShowLoginForm } from "../../redux/actions/authActions";
import { useRef } from "react";
import { useIsVisible } from "../../hooks/useIsVisible";

const BookingHistory = () => {
    const userData = useSelector(state => state.auth.userData);
    const bookingList = useSelector(state => state.api.bookingList);
    const bookingListTotalCount = useSelector(state => state.api.bookingListTotalCount);
    const bookingListLastLoadedPage = useSelector(state => state.api.bookingListLastLoadedPage);
    const bookingListLoading = useSelector(state => state.api.bookingListLoading);
    const queryParams = useSelector(state => state.common.queryParams);
    const [isLoading, setIsLoading] = useState(false);
    const [timeframeFilter, setTimeframeFilter] = useState(null);

    const pageEndRef = useRef(null);
    const isPageEndVisible = useIsVisible(pageEndRef);

    const [upcomingBookings, setUpcomingBookings] = useState([]);
    const [pastBookings, setPastBookings] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const PAGE_SIZE = 20

    useEffect(() => {
        if (userData?.id) {
            dispatch(resetBookingList());
            setTimeframeFilter("upcoming");
        } else {
            //dispatch(setShowLoginForm(true));
        }
    }, [userData])

    useEffect(() => {
        //console.log("isPageEndVisible", isPageEndVisible, bookingList?.length, bookingListTotalCount);
        if (isPageEndVisible && !bookingListLoading && bookingList?.length < bookingListTotalCount && bookingListLastLoadedPage >= 0) {
            if (timeframeFilter) {
                const filter = timeframeFilter + (queryParams?.orgId ? `&orgId=${queryParams.orgId}` : '');
                dispatch(fetchBookingsByCustomerId(userData?.id, PAGE_SIZE, filter));
            }
        }
    }, [isPageEndVisible])

    useEffect(() => {
        if (bookingList) {
            //console.log(curentFilter,bookingList.length, bookingListTotalCount, bookingListLastLoadedPage );
            setIsLoading(true);
            if (timeframeFilter === "upcoming") {
                if (bookingListLastLoadedPage >= 0) {
                    const bookingList_ = bookingList.sort((a, b) => a?.startSec - b?.startSec)
                    setUpcomingBookings(bookingList_);
                }
                if (bookingListLastLoadedPage >= 0 && bookingList.length >= bookingListTotalCount) {
                    dispatch(resetBookingList()) //reset redux states for upcoming list
                    setTimeframeFilter("past");
                }
            } else {
                setPastBookings(bookingList);
            }
            setTimeout(() => setIsLoading(false), 1000);
        }
    }, [bookingList])

    useEffect(() => {
        if (timeframeFilter) {
            const filter = timeframeFilter + (queryParams?.orgId ? `&orgId=${queryParams.orgId}` : '');
            dispatch(fetchBookingsByCustomerId(userData?.id, PAGE_SIZE, filter));
        }
    }, [timeframeFilter])

    return (
        <PrimaryLayout>
            {!isLoading && !userData?.id ?
                <>
                    <Alert
                        sx={{ justifyContent: 'center', borderRadius: 3, my: 10, maxWidth:'sm', mx:'auto' }}
                        severity="error"
                        onClick={() => dispatch(setShowLoginForm(true))}
                        action={
                            <Button color="inherit" size="small" onClick={() => dispatch(setShowLoginForm(true))}>
                                LOGIN
                            </Button>
                        }
                    >
                        Please login to access this page
                    </Alert>
                </>
                :
                <Box className="p-2 py-3 min-h-full">
                    {!bookingList?.length && (isLoading || bookingListLoading) ?
                        <>
                            <SkeletonCard cardType="bookingTimelineHead" />
                            <SkeletonCard cardType="bookingTimeline" />
                        </>
                        :
                        <Box sx={{ maxWidth: "md", mx: 'auto' }}>
                            {upcomingBookings.length == 1 ?
                                <Box sx={{ mt: 3 }}>
                                    <Divider>Upcoming Bookings</Divider>
                                    <Box sx={{ mt: 2 }}>
                                        <BookingDetailsCard booking={upcomingBookings[0]} index={0} />
                                    </Box>
                                </Box>
                                : upcomingBookings.length > 1 ?
                                    <Box sx={{ mt: 3 }}>
                                        <Divider>Upcoming Bookings</Divider>
                                        <Timeline position="alternate">
                                            {upcomingBookings.map((booking, idx) =>
                                                <TimelineItem key={idx}>
                                                    <TimelineSeparator>
                                                        <TimelineDot />
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent>
                                                        <BookingDetailsCard booking={booking} index={idx} />
                                                    </TimelineContent>
                                                </TimelineItem>
                                            )}
                                        </Timeline>
                                    </Box>
                                    : null
                            }
                            {pastBookings.length == 1 ?
                                <Box sx={{ mt: 3 }}>
                                    <Divider>Past Bookings</Divider>
                                    <Box sx={{ mt: 2 }}>
                                        <BookingDetailsCard booking={pastBookings[0]} index={0} />
                                    </Box>
                                </Box>
                                : pastBookings.length > 1 ?
                                    <Box sx={{ mt: 3 }}>
                                        <Divider>Past Bookings</Divider>
                                        <Timeline position="alternate" >
                                            {pastBookings.map((booking, idx) =>
                                                <TimelineItem key={idx}>
                                                    <TimelineSeparator>
                                                        <TimelineDot />
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent>
                                                        <BookingDetailsCard booking={booking} index={idx} />
                                                    </TimelineContent>
                                                </TimelineItem>
                                            )}
                                        </Timeline>

                                    </Box>
                                    : null
                            }
                            {!isLoading && !bookingListLoading && !upcomingBookings.length && !pastBookings.length && bookingListLastLoadedPage >= 0 && !bookingListTotalCount ?
                                <>
                                    <Alert sx={{ justifyContent: 'center', borderRadius: 3, my: 10 }} severity="error">No bookings</Alert>
                                </>
                                : bookingListTotalCount && bookingList?.length < bookingListTotalCount ?
                                    <>
                                        <SkeletonCard cardType="bookingTimeline" />
                                    </>
                                    : null
                            }
                        </Box>
                    }
                </Box>
            }
            <div ref={pageEndRef} />
        </PrimaryLayout>
    )
}

const BookingDetailsCard = ({ booking, index }) => {
    const queryParams = useSelector(state => state.common.queryParams);
    return (
        <Card sx={{ borderRadius: 3 }} >
            <CardActionArea component={Link} to={`/event/${booking.id}`}>
                <CardContent>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, direction: index % 2 == 0 ? "ltr" : "rtl" }}>
                        <Box>
                            <QRCode value={booking?.eventUrl} className="h-16 w-16 mx-auto" />
                            <Typography sx={{ fontSize: 14, fontWeight: 'bold', textAlign: "center" }}> {booking.bookingCode}</Typography>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 'bold', direction: 'ltr' }} >
                                {moment(booking.startSec * 1000).format("h:mm A,  Do MMMM YYYY")} ({moment.tz(moment.tz.guess()).zoneAbbr()})
                            </Typography>
                            <Typography sx={{ fontSize: 14, }}>
                                {booking.serviceName}
                            </Typography>
                            {queryParams?.orgId ? null :
                                <Typography sx={{ fontSize: 14, }}>
                                    {booking.organizationName}
                                </Typography>
                            }
                            <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}> {booking.status == "UNSPECIFIED" ? "CONFIRMED" : booking.status}</Typography>
                        </Box>
                    </Box>

                </CardContent>
            </CardActionArea>
        </Card >
    )
}


export default BookingHistory;