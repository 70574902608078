import moment from "moment-timezone";

export const ordinal_suffix_of = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}


export const isSameDay = unixTimestamp => moment(unixTimestamp * 1000).isSame(moment(), 'day')

export const formatPrice = (value, currency = "") => {
    switch (currency) {
        case "INR":
            return `₹${value}`;
        case "AUD":
                return `AUD ${value}`;
        case "USD":
                return `$${value}`;
        case "QAR":
                return `QAR ${value}`;

        default:
            return `${currency} ${value}`;
    }
}

export const  getDarkerColor = (hexColor, percent=20) => {
    // Remove the hash at the start if it's there
    hexColor = hexColor.replace(/^#/, '');

    // Parse the red, green, and blue components
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate the darker color by reducing brightness
    const darken = (color) => Math.max(0, Math.min(255, Math.round(color * (1 - percent / 100))));

    const rDarker = darken(r);
    const gDarker = darken(g);
    const bDarker = darken(b);

    // Convert back to hexadecimal and return the color
    return `#${((1 << 24) + (rDarker << 16) + (gDarker << 8) + bDarker).toString(16).slice(1).toUpperCase()}`;
}